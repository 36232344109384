import { Link } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import LandingPageImage from 'components/landing/LandingPageImage';

import commonCardCss from 'styles/components/common/melodyCard.scss';
import css from 'styles/components/common/melodyCardCategory.scss';

const MelodyCardCategory = ({
  cardData,
  altCss: { featureCss, imgCss } = {},
  eventLabel,
  onComponentClick,
  noBackground,
  melodyCardTestId,
  shouldLazyLoad,
  slotIndex,
  bottomAligned,
  isFullWidth,
  children = null,
  isSearchHeaderPlacement = false
}) => {
  const { msaImageUrl, image, name, alt, link, title, gae } = cardData;

  const { testId } = useMartyContext();

  return (
    <article
      className={cn(
        // this oddity of "pt" controls the size of the image on the card
        isSearchHeaderPlacement ? 'relative pt-[98%]' : commonCardCss.mCard,
        css.melodyCardCategory,
        {
          [commonCardCss.fullWidth]: isFullWidth
        },
        { 'rounded-lg border border-minimal': isSearchHeaderPlacement }
      )}
    >
      <Link
        to={link}
        onClick={evt => onComponentClick(evt, cardData)}
        data-eventlabel={eventLabel}
        data-eventvalue={gae}
        data-slotindex={slotIndex}
        data-test-id={testId(melodyCardTestId)}
      >
        <div
          className={cn(
            isSearchHeaderPlacement ? cn(css.image, 'mb-9') : cn(commonCardCss.image, css.categoryImage),
            { [commonCardCss.imageNoBackground]: noBackground },
            { [imgCss]: imgCss },
            { [css.bottomAligned]: bottomAligned }
          )}
        >
          <LandingPageImage src={msaImageUrl || image} alt={alt} title={title} shouldLazyLoad={shouldLazyLoad} isFullWidth={isFullWidth} />
        </div>
        <div className={cn(isSearchHeaderPlacement ? 'mx-3 mb-2' : css.categoryContent, { [featureCss]: featureCss })}>
          <p className={cn(isSearchHeaderPlacement ? css.categoryNameSearchHeader : css.categoryName)}>{name}</p>
        </div>
        {children}
      </Link>
    </article>
  );
};

export default MelodyCardCategory;
