import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { wildCardSearch } from 'actions/wildCard';
import TaxonomyBrandPage from 'containers/TaxonomyBrandPage';
import StandardSearch from 'containers/StandardSearch';
import Landing from 'containers/Landing';
import { Loader } from 'components/Loader';

export class WildCard extends Component {
  static fetchDataOnServer(store, { pathname, search }) {
    // zfc diamond api is based on the current uri.
    return store.dispatch(wildCardSearch({ pathname: pathname + search }));
  }

  componentDidMount() {
    const {
      location: { pathname },
      match: { url },
      uri: lastUri,
      wildCardSearch
    } = this.props;
    // need to populate uri in state and trick wildcard container into not refetching when its first mounted from ssr.
    if (url !== lastUri) {
      wildCardSearch({ pathname });
    }
  }

  render() {
    const { location, routeDetails = {}, route = {} } = this.props;
    const commonProps = { location };
    const { type, brand_id: brandId, page_name: pageName } = routeDetails;

    const paramPageName = { pageName };
    const paramBrandId = { brandId };
    const paramSeoName = { seoName: location.pathname };

    switch (type) {
      case 'landing':
        return <Landing {...commonProps} params={paramPageName} />;
      case 'brand':
        return <TaxonomyBrandPage {...commonProps} params={paramBrandId} />;
      case 'zso':
        return <StandardSearch {...commonProps} params={paramSeoName} route={route} />;
      case 'search':
        return <StandardSearch {...commonProps} params={paramSeoName} route={route} />;
      default:
        return <Loader />;
    }
  }
}

WildCard.contextTypes = {
  testId: PropTypes.func
};

function mapStateToProps(state) {
  const {
    wildCard: { uri, routeDetails },
    router: { location }
  } = state;

  return {
    routeDetails,
    uri,
    location
  };
}

export default connect(mapStateToProps, { wildCardSearch })(WildCard);
