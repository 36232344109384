import SponsoredAdsProduct from 'components/search/SponsoredAdsProduct';
import { getLowStockLabelStatus } from 'helpers/cardUtils';

const SponsoredAdsWrapper = ({
  sponsoredAdsResults,
  msaImageParams,
  trackSponsoredAdImpressions,
  makeSponsoredAdClick,
  numSponsoredAds,
  sponsoredAdsToShow,
  CardBottomSlot = null
}) => {
  const products =
    sponsoredAdsResults?.map((product, i) => ({
      ...product,
      index: i,
      isSponsored: true
    })) || [];

  if (!sponsoredAdsToShow) {
    return null;
  }

  return sponsoredAdsResults.map((ad, i) => {
    const { clickThruUrl, productUrl } = ad;
    const product = products[i];
    const { styleId, colorId } = product;
    product.isLowStock = getLowStockLabelStatus({ ...product });

    const { relatedStyles, ...productInfo } = product;

    return (
      styleId &&
      colorId && (
        <SponsoredAdsProduct
          key={`${styleId}-${colorId}`}
          product={product}
          relatedStyles={relatedStyles ? [productInfo].concat(relatedStyles || []) : []}
          numSponsoredAds={numSponsoredAds}
          trackSponsoredAdImpressions={trackSponsoredAdImpressions}
          makeSponsoredAdClick={makeSponsoredAdClick}
          msaImageParams={msaImageParams}
          CardBottomSlot={CardBottomSlot}
          includeSwatchRowSpace={true}
          clickThruUrl={clickThruUrl}
          microsoftEventUrl={productUrl}
        />
      )
    );
  });
};

export default SponsoredAdsWrapper;
