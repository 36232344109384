import ExecutionEnvironment from 'exenv';

import { RECEIVE_ROUTE_DETAILS, REQUEST_ROUTE_DETAILS } from 'constants/reduxActions';
import { fetchDiamondDetails, useDiamondOnServer } from 'apis/diamond';
import { pageTypeChange } from 'actions/common';
import {
  landingPageAuthRedirect,
  processReceivedTaxonomyBrandPageResponse,
  receiveLandingPageResponse,
  requestLandingPageInfo
} from 'actions/landing/landingPageInfo';
import { processReceivedSearchResponse, processReceivedZsoResponse } from 'actions/fancyUrls';
import { setFederatedLoginModalVisibility } from 'actions/headerfooter';
import { requestSearch } from 'actions/products';
import { redirectTo } from 'actions/redirect';
import { seoTermToHumanTerm, termToSeoPath } from 'helpers/SeoUtils';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { err, setError } from 'actions/errors';
import marketplace from 'cfg/marketplace.json';
import log from 'middleware/logger';

const { hasFederatedLogin } = marketplace;

export const requestRouteDetails = uri => ({
  type: REQUEST_ROUTE_DETAILS,
  uri
});

export const receiveRouteDetails = (uri, routeDetails) => ({
  type: RECEIVE_ROUTE_DETAILS,
  uri,
  routeDetails
});

export function wildCardSearch({ pathname }, fetchRouteDetails = fetchDiamondDetails) {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      client: { request }
    } = appState;
    dispatch(requestRouteDetails(pathname));

    // client side op use fetch based action to invoke the server fn.
    // use old diamond for clientside requests for now.
    if (ExecutionEnvironment.canUseDOM) {
      return fetchRouteDetails({ uri: pathname }, request, appState)
        .then(fetchErrorMiddleware)
        .then(res => dispatch(processDiamondRouteResponse(pathname, res)))
        .catch(e => dispatch(setError(err.GENERIC, e, 404)));
    } else {
      // server-side new diamond fetch.
      return useDiamondOnServer() // ie: { diamond } from 'server/routes/zfcDiamond/index.ts'
        .then(diamond => diamond(pathname, appState).then(res => dispatch(processDiamondRouteResponse(pathname, res))));
    }
  };
}

// take the diamond response object and process it- switch over the page type and dispatch the relevant actions.
export function processDiamondRouteResponse(uri, response) {
  return (dispatch, getState) => {
    const appState = getState();
    const { proxy_response: proxyResponse, route_details: routeDetails } = response;
    const { type, location } = routeDetails;

    dispatch(receiveRouteDetails(uri, routeDetails));

    // zfc-diamond specific: if for some reason they send us back the same url redirect to a search
    if (location && location === uri) {
      log('wildcard zfc-diamond returned the same url we sent. Redirecting to a term search');
      return dispatch(redirectTo(`search?term=${location.replace('/', '')}`));
    }

    // zfc-diamond specific: if supplied a location redirect to it.
    if (location) {
      dispatch(redirectTo(location));
    }

    const url = termToSeoPath(uri);
    const term = seoTermToHumanTerm(uri);

    switch (type) {
      case 'brand':
        const { brand_id: brandId } = routeDetails;
        dispatch(pageTypeChange('brand'));
        return processReceivedTaxonomyBrandPageResponse(proxyResponse, brandId, dispatch, getState);
      case 'landing':
        const { page_name: pageName } = routeDetails;
        const { pageType, customerAuth } = proxyResponse; // todo: where does customerAuth come from?
        dispatch(requestLandingPageInfo(pageName));
        if (pageType === 'AuthenticationFull' && customerAuth !== 'FULL') {
          if (hasFederatedLogin) {
            const returnTo = appState.router.location.pathname;
            return dispatch(
              setFederatedLoginModalVisibility(true, {
                returnTo,
                redirectOnClose: '/'
              })
            );
          } else {
            return dispatch(landingPageAuthRedirect(pageName));
          }
        } else {
          dispatch(pageTypeChange('landing'));
          return dispatch(receiveLandingPageResponse(proxyResponse, pageName));
        }
      case 'zso':
        dispatch(pageTypeChange('search'));
        dispatch(requestSearch({ url, isFresh: true }));
        return processReceivedZsoResponse({ term, url }, proxyResponse, dispatch, getState);
      case 'search':
        dispatch(pageTypeChange('search'));
        dispatch(requestSearch({ url, isFresh: true }));
        return processReceivedSearchResponse(proxyResponse, dispatch, getState, {}, url);
      case 'redirect':
        // redirect already dispatched above, nothing else to do.
        return;
      case 'external_redirect':
        // redirect already dispatched above, nothing else to do.
        return;
      default:
        return null;
    }
  };
}
