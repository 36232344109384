import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import ProductCard from 'components/common/ProductCard';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { evRecommendationClick, evRecommendationImpressionWrapper } from 'events/recommendations';
import { trackEvent } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import useMartyContext from 'hooks/useMartyContext';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import { evProductInteract } from 'events/search';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';

import css from 'styles/components/search/inlineRecos.scss';

export const SearchInlineRecos = ({ inlineRecos = {}, heartsInfo, msaImageParams, searchTerm, CardBottomSlot = null, titleClassName }) => {
  const { recos, title } = inlineRecos;
  const recoSource = 'EP13N';
  const { testId } = useMartyContext();
  const recoProductRelations = useSelector(state => state.products.recoProductRelations);

  const handleInlineRecoClick = useCallback(
    ({ index, styleId, productId }) => {
      trackEvent('TE_SEARCH_INLINE_RECO_CLICK', `styleId:${styleId}productIndex:${index}:searchTerm:${searchTerm}`);
      track(() => [
        evRecommendationClick,
        {
          index: index + 1,
          recommendationType: 'PRODUCT_RECOMMENDATION',
          recommendationValue: productId,
          recommendationSource: recoSource,
          widgetType: 'SIMILAR_PRODUCT_WIDGET',
          sourcePage: 'SEARCH_PAGE'
        }
      ]);
    },
    [searchTerm]
  );

  useEffect(() => {
    const styleIds = recos.map(reco => reco.styleId);
    trackEvent('TE_SEARCH_INLINE_RECOS_VIEW', `styles:${styleIds}:searchTerm:${searchTerm}`);
    const recommendationImpression = [
      {
        numberOfRecommendations: styleIds.length,
        recommendationType: 'PRODUCT_RECOMMENDATION',
        recommendationSource: recoSource,
        widgetType: 'SIMILAR_PRODUCT_WIDGET'
      }
    ];

    track(() => [evRecommendationImpressionWrapper, { recommendationImpression }]);
  }, [recos, searchTerm]);

  return (
    recos?.length && (
      <>
        {title && <h2 className={titleClassName}>{title}</h2>}
        <div className={css.recos} data-source={recoSource} data-test-id={'inlineRecos'}>
          {recos.map((reco, index) => {
            if (index < 4) {
              const { styleId, productId, link, productUrl } = reco;
              const url = link || productUrl; // if cabt wins won't need to explicitly send this; handled by ProductCard.jsx
              const productWithRelatedStyles = getProductWithRelatedStyles(styleId, recoProductRelations);
              const { relatedStyles, ...productInfo } = productWithRelatedStyles;

              if (relatedStyles?.length) {
                const onProductMediaHovered = (product, mainProductStyleId) => {
                  track(() => [
                    evProductInteract,
                    {
                      mainStyleId: mainProductStyleId,
                      interactedProduct: product,
                      interactionType: 'HOVER'
                    }
                  ]);
                };

                return (
                  <ColorSwatchWrapper
                    {...productInfo}
                    relatedStyles={relatedStyles}
                    heartsInfo={heartsInfo}
                    key={`${productInfo.styleId}-${productInfo.colorId}`}
                    testId={testId('searchReco')}
                    msaImageParams={msaImageParams}
                    onClick={() => handleInlineRecoClick({ index, styleId, productId })}
                    onProductMediaHovered={onProductMediaHovered}
                    // reco exclusives
                    className={cn(css.productCardOverride)}
                    productUrl={url}
                    badges={productInfo.badges}
                    imageClassName={reco.rowHasBadge && css.rowHasBadge}
                  />
                );
              }

              const recoOtherProps = { ...reco, productType: productInfo.productType, txAttrFacet_Gender: productInfo.txAttrFacet_Gender };

              return (
                <ProductCard
                  {...recoOtherProps}
                  hearts={heartsInfo}
                  msaImageParams={msaImageParams}
                  key={styleId + productId}
                  className={css.productCardOverride}
                  imageNoBackground={false}
                  productUrl={url}
                  onClick={() => handleInlineRecoClick({ index, styleId, productId })}
                  data-test-id={testId('searchReco')}
                  CardBottomSlot={CardBottomSlot}
                />
              );
            }
            return null;
          })}
        </div>
      </>
    )
  );
};

export default withErrorBoundary('SearchInlineRecos', SearchInlineRecos);
