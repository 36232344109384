import { createSelector } from 'reselect';

import type { AppState } from 'types/app';
import type { DevicePropsState } from 'store/ducks/deviceProps/types';

// Selectors
export const selectLayoutState = (state: AppState): DevicePropsState => state.deviceProps;

export const selectViewportSize = createSelector(selectLayoutState, deviceProps => ({
  width: deviceProps.viewportWidth,
  height: deviceProps.viewportHeight
}));

export const selectLayout = createSelector(selectLayoutState, deviceProps => deviceProps.layout);

export const selectIsDesktop = createSelector(selectLayout, layout => layout === 'desktop');

export const selectIsMobile = createSelector(selectLayout, layout => layout === 'mobile');
