import { ProductCarousel } from '@mweb/zappos-ui/ProductCarousel';

import useMartyContext from 'hooks/useMartyContext';

interface SponsoredBrandsCarouselProps {
  heading: string | null;
  slides: React.ReactNode[];
  overrides?: {
    root?: string;
    item?: string;
    backButton?: string;
    forwardButton?: string;
  };
}

const SponsoredBrandsCarousel = (props: SponsoredBrandsCarouselProps) => {
  const { heading, slides, overrides } = props;
  const { testId } = useMartyContext();

  return (
    <section className="flex flex-col gap-4" data-test-id={testId('sponsoredBrandsCarousel')}>
      <h2 className="text-xl/none font-semibold">{heading}</h2>
      <ProductCarousel overrides={overrides} options={{ slidesToScroll: 1 }} slides={slides} />
    </section>
  );
};

export default SponsoredBrandsCarousel;
