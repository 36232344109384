import { useRef } from 'react';
import { deepEqual } from 'fast-equals';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { PRODUCT_CARD_BREAKPOINT_MAX, PRODUCT_CARD_BREAKPOINT_MIN } from 'constants/appConstants';
import type { Product } from 'constants/searchTypes';
import { selectLayoutState } from 'store/ducks/deviceProps/selectors';

const selectProductsPerRow = createSelector([selectLayoutState], deviceProps => {
  const width = Math.min(deviceProps.viewportWidth || 0, deviceProps.screenWidth || 0);
  if (width > PRODUCT_CARD_BREAKPOINT_MAX) {
    return 4;
  } else if (width <= PRODUCT_CARD_BREAKPOINT_MIN) {
    return 2;
  } else {
    return 3;
  }
});

/**
 * on the first client side commit and every server side commit,
 * this will return [].  A subsequent commit will be triggered.
 *
 * on subsequent commits, this will add a rowHasBadge attribute to each product
 * if any product in the products row has a badge.
 *
 * The number of rows is determined by the hook useProductsPerRow in this module.
 */
const useRowHasBadge = (list: Product[]) => {
  const ref = useRef({ fullList: [] as Product[] });
  const productsPerRow = useSelector(selectProductsPerRow);

  if (productsPerRow) {
    const badgeRowsMap: { [key: number]: boolean } = {};

    list.forEach((product, i) => {
      const rowCount = Math.floor(i / productsPerRow);
      if (product.badges && product.badges.length > 0 && !badgeRowsMap[rowCount]) {
        badgeRowsMap[rowCount] = true;
      }
    });

    const newList = list.map((product, i) => (badgeRowsMap[Math.floor(i / productsPerRow)] ? { ...product, rowHasBadge: true } : product));
    if (!deepEqual(newList, ref.current.fullList)) {
      ref.current.fullList = newList;
    }
  }

  return { formattedList: ref.current.fullList };
};

export default useRowHasBadge;
