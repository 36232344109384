import { useEffect } from 'react';

import { cn } from 'helpers/classnames';
import useWindowSize from 'hooks/useWindowSize';
import { PRODUCT_CARD_BREAKPOINT_MAX, PRODUCT_CARD_BREAKPOINT_MIN } from 'constants/appConstants';

const AdsRecosRow = ({ WrapperEl = 'div', attributes, className, rowIndex = 1, cardCount, debounceWait = 500, children }) => {
  const { width: windowWidth } = useWindowSize(debounceWait);

  useEffect(() => {
    if (windowWidth) {
      const getProductCardIndex = (width, count) => {
        const loc = width <= PRODUCT_CARD_BREAKPOINT_MIN ? 2 : width <= PRODUCT_CARD_BREAKPOINT_MAX ? 3 : 4;
        return count < loc ? count - 1 : rowIndex * loc - 1;
      };

      const cardIndex = rowIndex ? getProductCardIndex(windowWidth, cardCount) : 0;
      const cardElem = document.querySelectorAll('#products > article')[cardIndex];

      const wrapper = document.getElementsByClassName(cn(className))[0];

      if (wrapper && cardElem) {
        rowIndex ? cardElem?.after(wrapper) : cardElem?.before(wrapper);
      }
    }
  }, [windowWidth, cardCount, rowIndex, className]);

  return (
    <WrapperEl className={cn(className)} {...attributes}>
      {children}
    </WrapperEl>
  );
};

export default AdsRecosRow;
