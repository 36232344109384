import React, { useEffect, useRef, useState } from 'react';

import { cn } from 'helpers/classnames';
import { evFacetClick } from 'events/search';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { FILTERS_RE } from 'common/regex';
import { trackEvent } from 'helpers/analytics';
import { track } from 'apis/amethyst';
import useMartyContext from 'hooks/useMartyContext';
import useWindowSize from 'hooks/useWindowSize';
import { firePixelEvent } from 'actions/pixelServer';
import { useRefScrollDimensions } from 'hooks/useRefScrollDimensions';

import css from 'styles/components/search/compactSingleSelects.scss';

export const CompactSingleSelects = ({
  filters,
  className,
  hasAutoScroll,
  removePersonalizedSize,
  handlePillClick,
  clearFilters,
  getFacetUrlParams,
  getFacetUrlPath,
  clearAutoComplete
}) => {
  const {
    testId,
    router,
    marketplace: {
      search: { maxPills }
    }
  } = useMartyContext();
  const breadcrumbWrapper = useRef();
  const [breadcrumbDimensions, setBreadcrumbRef] = useRefScrollDimensions({
    includes: {
      scrollLeft: true,
      scrollWidth: true,
      clientWidth: true
    },
    setRef: breadcrumbWrapper
  });
  const [scrollingBreadcrumbs, setScrollingBreadcrumbs] = useState(false);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const { scrollLeft, scrollWidth, clientWidth } = breadcrumbDimensions || {};
  const maxScroll = scrollWidth - clientWidth;

  const selectionTitle = filters.breadcrumbs.length ? 'Your Selections' : filters?.pills?.recommendations?.length ? 'Suggested Filters' : '';
  const isShowBreadcrumbsAndRecommendations = !filters.breadcrumbs.length && !filters?.pills?.recommendations?.length;

  useEffect(() => {
    if (windowWidth && breadcrumbWrapper.current) {
      setShowScrollButtons(hasAutoScroll && scrollWidth - clientWidth > 0);
    }
    firePixelEvent('search');
  }, [windowWidth, hasAutoScroll, scrollWidth, clientWidth]);

  if (!filters.breadcrumbs.length && !filters?.pills?.recommendations?.length) {
    return null;
  }

  const makeBreadcrumbClickHandler = crumb => e => {
    e.preventDefault();
    if (filters?.personalizedSize?.sizes?.includes(crumb.name)) {
      removePersonalizedSize();
    }
    const facetGroup = crumb.removeName.split(' ')[1]?.replace(/:/g, '');
    clearAutoComplete(facetGroup);

    router.forceBrowserPush(crumb.removeUrl);
    track(() => [
      evFacetClick,
      {
        breadcrumbRemove: crumb,
        facetClickSource: 'FACET_PILLS',
        selected: false,
        deselected: true
      }
    ]);
  };

  const makeCrumbContent = (crumb, isSingleSelect) => {
    const crumbTitle = `Remove ${crumb.name} filter`;
    return (
      <a // eslint-disable-line jsx-a11y/anchor-is-valid
        href={!FILTERS_RE.test(crumb.removeUrl) ? crumb.removeUrl : ''}
        aria-label={crumbTitle}
        data-singleselect={isSingleSelect}
        tabIndex={0}
        onClick={makeBreadcrumbClickHandler(crumb)}
        data-test-id={testId('singleSelectBreadcrumb')}
      >
        {crumb.name}
      </a>
    );
  };

  const onPillClick = (crumb, index) => {
    if (breadcrumbWrapper.current) {
      breadcrumbWrapper.current.scrollLeft = 0;
    }

    getFacetUrlParams(crumb.facetUrl);
    getFacetUrlPath(crumb.facetUrl);
    handlePillClick(crumb, index);
    trackEvent('TE_SEARCH_PILLCLICKED', `${crumb.name}:${crumb.value}`);
    track(() => [
      evFacetClick,
      {
        crumb,
        facetClickSource: 'FACET_PILLS',
        selected: true,
        deselected: false
      }
    ]);
  };

  const makePillMarkup = () => {
    const breadCrumbs = filters.breadcrumbs.map(crumb => {
      const { name, removeUrl } = crumb;
      const isSingleSelect = Object.keys(filters.selected.singleSelects).some(e => filters.selected.singleSelects[e][0] === name);
      return (
        <li
          key={`${removeUrl}-${name}`}
          className={cn({
            [css.onSaleFacet]: name === 'On Sale',
            [css.defaultTerm]: name === 'Search'
          })}
        >
          {makeCrumbContent(crumb, isSingleSelect)}
        </li>
      );
    });

    const makePills = filters?.pills?.recommendations.map((crumb, i) => {
      if (i < maxPills + 1) {
        return (
          <li key={`${crumb.value}-${crumb.name}`} className={css.pills}>
            <button type="button" key={i} onClick={() => onPillClick(crumb, i)} aria-label={`Select ${crumb.value} filter`}>
              {crumb.value}
            </button>
          </li>
        );
      }
    });
    return breadCrumbs.concat(makePills);
  };

  const scrollBreadCrumbs = (isScrollingLeft, scrollTo) => {
    if (breadcrumbWrapper.current) {
      const { scrollLeft } = breadcrumbWrapper.current;
      setScrollingBreadcrumbs(true);
      if ((isScrollingLeft && scrollLeft > scrollTo) || (!isScrollingLeft && scrollLeft < scrollTo)) {
        breadcrumbWrapper.current.scrollLeft += isScrollingLeft ? -20 : 20;
        setTimeout(scrollBreadCrumbs, 10, isScrollingLeft, scrollTo);
      } else {
        setScrollingBreadcrumbs(false);
      }
    }
  };

  const onScrollBreadcrumbs = e => {
    const { scrollLeft, clientWidth } = breadcrumbDimensions;

    if (scrollingBreadcrumbs) {
      e.preventDefault();
    } else {
      const isScrollingLeft = e.target.className === css.leftScroll;
      let scrollTo = scrollLeft + clientWidth < maxScroll ? scrollLeft + clientWidth : maxScroll;

      if (isScrollingLeft) {
        scrollTo = scrollLeft - clientWidth > 0 ? scrollLeft - clientWidth : 0;
      }

      scrollBreadCrumbs(isScrollingLeft, scrollTo);
    }
  };

  return (
    <div className={css.container}>
      <div
        className={cn(css.compactSingleSelects, className || null, {
          [css.hasAutoScroll]: hasAutoScroll,
          [css.pillsCompactSingleSelects]: hasAutoScroll
        })}
        data-test-id={testId('breadcrumbScroll')}
      >
        <div className={css.headerContainer}>
          <h2 className={css.selectionText} data-test-id={testId('breadcrumbTitle')}>
            {selectionTitle}
          </h2>
        </div>

        {showScrollButtons && (
          <button
            type="button"
            onMouseDown={onScrollBreadcrumbs}
            tabIndex="-1"
            disabled={scrollLeft <= 0}
            className={css.leftScroll}
            data-test-id={testId('leftBreadcrumbScroll')}
          >
            Left Scroll
          </button>
        )}
        <ul
          className={cn({
            [css.hide]: isShowBreadcrumbsAndRecommendations
          })}
          id="searchSelectedFilters"
          ref={setBreadcrumbRef}
          data-test-id={testId('breadcrumbContainer')}
        >
          {makePillMarkup()}
        </ul>
        {showScrollButtons && (
          <button
            type="button"
            onMouseDown={onScrollBreadcrumbs}
            tabIndex="-1"
            disabled={scrollLeft === maxScroll}
            className={css.rightScroll}
            data-test-id={testId('rightBreadcrumbScroll')}
          >
            Right Scroll
          </button>
        )}
      </div>
      {filters?.breadcrumbs?.length > 0 && (
        <button type="button" className={css.clearFiltersInlineBtn} onClick={clearFilters}>
          Clear all filters
        </button>
      )}
    </div>
  );
};

export default withErrorBoundary('CompactSingleSelects', CompactSingleSelects);
