import React, { useCallback, useEffect } from 'react';

import { cn } from 'helpers/classnames';
import SponsoredBrandsCarousel from 'components/landing/SponsoredBrandsCarousel';
import MelodyCardCategory from 'components/common/melodyCard/MelodyCardCategory';
import { evCategoryStreamClick, evCategoryStreamImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/landing/melodyCategory.scss';

const stylesWithNoBackground = ['bottomAlignedWithNoBackground'];
const stylesWithBottomAligned = ['bottomAlignedWithNoBackground'];

interface Category {
  image: string;
  name: string;
  alt?: string;
  link: string;
  gae: string;
}

interface SlotDetails {
  heading: string | null;
  style: string;
  categories: Category[];
}

export interface MelodyCategoryProps {
  slotName: string;
  slotDetails: SlotDetails;
  slotIndex: number;
  onComponentClick: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
  shouldLazyLoad: boolean;
  isFullWidth: boolean;
}

export const MelodyCategory = ({ slotName, slotDetails, slotIndex, onComponentClick, shouldLazyLoad, isFullWidth }: MelodyCategoryProps) => {
  const { heading, style, categories } = slotDetails || {};
  const noBackground = stylesWithNoBackground.includes(style);
  const bottomAligned = stylesWithBottomAligned.includes(style);
  const isSearchTopSlot = slotName === 'search-header-1';
  const carouselButtonClasses = 'rounded-full p-2 border border-minimal hover:border-active active:border-button-primary backdrop-blur-[0.5px]';

  useEffect(() => {
    (slotDetails.categories || []).forEach(category => {
      track(() => [evCategoryStreamImpression, { slotDetails: { ...slotDetails, ...category }, slotName, slotIndex, personalized: false }]);
    });
  }, [slotDetails, slotIndex, slotName]);

  const onClick = useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement>, card: {}) => {
      onComponentClick && onComponentClick(evt);
      track(() => [
        evCategoryStreamClick,
        {
          slotDetails: { ...slotDetails, ...card },
          slotName,
          slotIndex,
          personalized: false
        }
      ]);
    },
    [onComponentClick, slotDetails, slotIndex, slotName]
  );

  if (categories) {
    if (style === 'featureNav') {
      const altCss = {
        featureCss: css.featureCss,
        imgCss: css.imgCss
      };

      return (
        <div
          className={cn(css.wrap, { [css.fullWidth]: isFullWidth }, { ['md:float-right md:w-[calc(100%-232px)]']: isSearchTopSlot })}
          data-slot-id={slotName}
        >
          <div className={css.items}>
            {categories.slice(0, 4).map((category: Category, i: number) => {
              const { gae } = category;
              return (
                <MelodyCardCategory
                  slotIndex={null}
                  bottomAligned={false}
                  noBackground={false}
                  cardData={category}
                  onComponentClick={onClick}
                  key={`${gae}-${i}`}
                  eventLabel="melodyCategoryFeatureNav"
                  melodyCardTestId="melodyCategoryFeatureNav"
                  shouldLazyLoad={shouldLazyLoad}
                  altCss={altCss}
                  isFullWidth={isFullWidth}
                />
              );
            })}
          </div>
        </div>
      );
    }

    if (isSearchTopSlot) {
      return (
        <div
          className={cn(css.wrap, { [css.fullWidth]: isFullWidth }, 'mb-7 sm:mb-11 md:float-right md:w-[calc(100%-232px)]')}
          data-slot-id={slotName}
        >
          <SponsoredBrandsCarousel
            overrides={{
              root: '[--slide-gap:16px]',
              item: 'basis-[45%] @2xl/carousel:basis-1/4 @3xl/carousel:basis-1/5',
              backButton: cn('left-3', carouselButtonClasses),
              forwardButton: cn('right-3', carouselButtonClasses)
            }}
            heading={heading}
            slides={categories.map((category, i) => {
              const { gae, name } = category;
              return (
                <MelodyCardCategory
                  isSearchHeaderPlacement={isSearchTopSlot}
                  slotIndex={null}
                  cardData={category}
                  onComponentClick={onClick}
                  key={`${gae}-${i}`}
                  eventLabel="melodyCategory"
                  melodyCardTestId={`melodyCategory-${name}`}
                  shouldLazyLoad={shouldLazyLoad}
                  bottomAligned={bottomAligned}
                  noBackground={noBackground}
                  isFullWidth={isFullWidth}
                />
              );
            })}
          />
        </div>
      );
    }

    return (
      <div className={cn(css.wrap, { [css.fullWidth]: isFullWidth })} data-slot-id={slotName}>
        <Carousel
          slides={categories.map((category: Category, i: number) => {
            const { gae, name } = category;
            return (
              <MelodyCardCategory
                slotIndex={null}
                cardData={category}
                onComponentClick={onClick}
                key={`${gae}-${i}`}
                eventLabel="melodyCategory"
                melodyCardTestId={`melodyCategory-${name}`}
                shouldLazyLoad={shouldLazyLoad}
                bottomAligned={bottomAligned}
                noBackground={noBackground}
                isFullWidth={isFullWidth}
              />
            );
          })}
          header={{ title: heading ? heading : undefined }}
        />
      </div>
    );
  }

  return null;
};

export default MelodyCategory;
