import React, { useEffect } from 'react';

import LandingSlot from 'containers/LandingSlot';
import useMartyContext from 'hooks/useMartyContext';
import { NO_SEARCH_RESULTS_HELP_VERBIAGE, NO_SEARCH_RESULTS_VERBIAGE } from 'constants/appConstants';
import { trackPageEvent } from 'utils/contentSquare/uxaHelper';
import EventName from 'utils/contentSquare/eventNames';
import { PageLoader } from 'components/Loader';

import css from 'styles/components/search/noSearchResults.scss';

const NoSearchResults = ({ fetchLandingPageInfo, filters: { originalTerm }, landingPage: { isLoaded, pageInfo: { slotData } = {}, slotOrder } }) => {
  const { testId } = useMartyContext();

  useEffect(() => {
    trackPageEvent(EventName.NO_SEARCH_RESULTS_FOUND);
  }, []);

  useEffect(() => {
    fetchLandingPageInfo('no-search-results');
  }, [fetchLandingPageInfo]);

  return (
    <div className={css.container} data-test-id={testId('noSearchResults')}>
      <div className="flex flex-col gap-y-3">
        <h1 className="m-0 text-2xl font-normal" data-test-id={testId('noResultsHeader')}>
          {NO_SEARCH_RESULTS_VERBIAGE} {originalTerm ? <span className="font-bold">{originalTerm}</span> : 'your search'}
        </h1>
        <p className="mb-10 text-lg" data-test-id={testId('noResultsMessage')}>
          {NO_SEARCH_RESULTS_HELP_VERBIAGE}
        </p>
      </div>
      {isLoaded ? (
        <>
          {slotOrder.map(slotName => (
            <LandingSlot
              key={slotName}
              slotName={slotName}
              data={slotData[slotName]}
              onComponentClick={() => {}}
              slideWidths={getSlotSlideWidths(slotData[slotName]?.componentName)}
            />
          ))}
        </>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

const getSlotSlideWidths = componentName => {
  const sixDesktopSlideComponents = ['melodyPersonalizedBrand', 'melodyCategory'];
  return { mobile: '33.3%', tablet: '25%', desktop: sixDesktopSlideComponents.includes(componentName) ? '16.66%' : '20%' };
};

export default NoSearchResults;
